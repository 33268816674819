import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>Inici al gener!</strong>
                </p>
                <p>
                    Us presentem el nou curs presencial de preparació per a l'accés a Mossos
                    d'Esquadra 2024/25 en format intensiu de 40 hores. Els dimarts i els dijous de
                    15:00 a 21:00 h.
                </p>
                <p>
                    Ara ja pots reservar la teva plaça i reforçar els teus coneixements aquest estiu
                    amb el <strong>CAMPUS online</strong>. A iOpos tenim la nostra pròpia
                    metodologia i any rere any obtenim els millors resultats. Tots els alumnes
                    inicien i finalitzen junts el curs, assolint tots els continguts de la manera
                    més eficient.
                </p>
                <p>
                    <strong>La formació consta de:</strong>
                </p>
                <ul>
                    <li>
                        Accés a un campus online amb tota l'actualitat setmanal en forma de
                        notícies, tests per posar-vos a prova i vídeo resum setmanal.
                    </li>
                    <li>Formació didàctica i simulacres.</li>
                    <li>
                        40 hores de classes presencials amb explicacions dels conceptes més
                        susceptibles de sortir en examen tant a nivell teòric com de psicotècnics
                        aptitudinals.
                    </li>
                    <li>Accés a un grup exclusiu de WhatsApp amb reptes setmanals.</li>
                    <li>
                        Contacte directe amb la docent que imparteix el curs per resoldre qualsevol
                        dubte.
                    </li>
                </ul>
                <p>
                    El primer dia de curs us donarem la nostra guia de Mossos d'Esquadra en paper i
                    un dossier de psicotècnics confeccionat pel nostre equip de psicòlegs.
                </p>
                <p>
                    <strong>Dates del curs:</strong>
                </p>
                <ul>
                    <li>Inici: el 23 de gener de 15 a 21h</li>
                    <li>28 de gener de 15 a 21h</li>
                    <li>30 de gener de 15 a 21h</li>
                    <li>4 de febrer de 15 a 21h</li>
                    <li>6 de febrer de 15 a 21h</li>
                    <li>11 de febrer de15 a 21h</li>
                    <li>Fi de curs: 13 de febrer de 16:00 a 20:00 h</li>
                </ul>
                <p>
                    El curs es realitza al c/Martí Codolar núm. 18, Hospitalet de Llobregat. Les
                    places són limitades!
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/aJLUMYk5Iqs"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Curs intensiu Mossos d'Esquadra (40 hores), dimarts i dijous de gener i febrer de 15:00 a 21:00 h!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
